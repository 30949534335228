<template>
  <header ref="header">
    <nav ref="nav">
      <div class="nav-top-bar">
        <TopBarCarousel />
      </div>
      <div class="nav-btm-br">
        <div class="logo">
          <router-link :to="{ name: 'home' }">
            <img
              src="../assets/logo.png"
              alt="logo"
              loading="lazy"
              class="logo-img"
            />
          </router-link>
        </div>
        <div class="menu-items" ref="menu" @click="model">
          <ul>
            <li>
              <router-link :to="{ name: 'home' }">Home</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ImageGallery' }">Gallery</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'about' }">About US</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ContactUs' }">Contact US</router-link>
            </li>
          </ul>
        </div>
        <div class="burger" ref="burger" @click="model" v-if="modeOpen">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>
        <div class="burger" ref="burger" @click="model" v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </nav>
    <!-- <div class="mobile-menu-btm">
      <div>
        <router-link :to="{ name: 'home' }"
          ><i class="fa fa-home" aria-hidden="true"></i
        ></router-link>
      </div>
      <div>
        <router-link :to="{ name: 'Catelogueview', params: { id: 'shope' } }"
          ><i class="fa fa-th-large" aria-hidden="true"></i
        ></router-link>
      </div>
      <div>
        <router-link :to="{ name: 'ContactUs' }"
          ><i class="fa fa-address-book-o" aria-hidden="true"></i
        ></router-link>
      </div>
      <div>
        <a href="tel:+918590400877">
          <i class="fa fa-phone" aria-hidden="true"></i>
        </a>
      </div>
    </div> -->
    <div class="whatsapp" ref="whatsapp">
      <a class="up" @click="up($event)">up</a>
      <a href="https://wa.me/8590400877" target="_blank" class="tooltip">
        <span class="tooltiptext">+91859040087</span>
        <i class="fa fa-whatsapp" aria-hidden="true"></i>
      </a>
    </div>
  </header>
</template>
<script>
import TopBarCarousel from "./TopBarCarousel.vue";
export default {
  name: "NavbarView",
  components: { TopBarCarousel },
  data() {
    return {
      observer: null,
      modeOpen: true,
    };
  },
  methods: {
    model() {
      this.$refs.menu.classList.toggle("visible");
      this.modeOpen = !this.modeOpen;
    },

    up(e) {
      // window.scrollBy(0, -window.innerHeight);

      let length_y = e.pageY;
      const j = 0;

      const looper = () => {
        setTimeout(() => {
          length_y -= 100;

          if (j < length_y) {
            window.scrollBy(0, -100);
            looper();
          }
        }, 100);
      };
      if (j < length_y) looper();
    },

    obaserverCallback(entries) {
      // const [e]=entries
      try {
        entries.forEach((e) => {
          if (!e.isIntersecting) {
            this.$refs.whatsapp.style.display = "flex";
            this.$refs.nav.classList.add("sticky");
          } else {
            this.$refs.nav.classList.remove("sticky");
            this.$refs.whatsapp.style.display = "none";
          }
        });
      } finally {
        // always runs
      }
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.obaserverCallback, {
      root: null,
      threshold: 1,
      rootMargin: `${1000}px`,
    });
    try {
      this.observer.observe(this.$refs.header);
    } finally {
      // always runs
    }
  },

  beforeUnmount() {
    this.observer.unobserve(this.$refs.header);
  },
};
</script>
<style Scoped>
.tooltip:hover > .tooltiptext {
  display: block;
  background-color: #25d366;
}
.tooltiptext {
  display: none;
  position: absolute;
  z-index: 103;
  font: 1em sans-serif;
  left: -20px;
  top: -20px;
}

nav {
  position: absolute;
  top: 0;
  z-index: 99;
  height: var(--nav-width);
}
.nav-top-bar {
  max-width: 100vw;
  height: 25%;
  background: var(--secondry--color);

  cursor: pointer;
  overflow-x: auto;
  display: flex;
  align-items: center;

  font-size: 1.8rem;
  color: grey;
}

.nav-btm-br {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 75%;
  width: 100vw;

  /* background: url("../assets/navold.jpg"); */
  background-color: #fff;
  background-size: cover;
  padding-right: 3rem;
  background-position: center;
  background-size: contain;
  /* box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.5),
    25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
    inset -20px -20px 25px rgb(255, 255, 255, 0.9); */
  box-shadow: 5px 5px 5px grey;
}
.nav-btm-br .logo {
  height: fit-content;
  width: auto;

  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3rem;
}
.sticky {
  position: fixed;
}
.logo-img {
  height: 70px;
  width: 100%;
}

.nav-btm-br .burger:hover {
  transform: scale(1.2);
  transition: all 350ms;
  /* border: 5px solid var(--secondry--color); */
}
.menu-items ul li {
  list-style: none;
  display: inline-block;
  padding-right: 20px;
  text-align: center;
  color: white;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.mobile-menu-btm {
  display: none;
}
.menu-items ul li:hover {
  transform: scale(1.05);
  transition: all 350ms;
  color: var(--primary--color);
  cursor: pointer;
}

.txt-shipment {
  font-size: 1rem;
}
.menu-items {
  display: none;
  z-index: 100;
  position: fixed;
  top: var(--nav-width);
  left: 0;
  width: 100vw;
  height: 90vh;
  /* background: url("../assets/navold.jpg"); */
  /* box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.5),
      25px 35px 20px rgb(0, 0, 0, 0.5), 25px 30px 30px rgb(0, 0, 0, 0.5),
      inset -20px -20px 25px rgb(255, 255, 255, 0.9); */
  animation: slide 1s linear alternate;
  /* background-color: white; */
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: #fff;
}
.menu-items ul li {
  display: list-item;
  padding: 20px;
  border-bottom: 1px solid black;
  width: 100vw;
  text-align: center;
}
.burger {
  display: flex;
  position: absolute;
  right: 10px;
  border-color: red;
  height: 100%;
  width: 50px;
  align-items: center;
  transition: all 1s linear;
  font-size: 3rem;
  margin: 0;
  background-color: transparent;
  justify-content: center;
  cursor: pointer;
}
.mobile-menu-btm {
  /* background: url("../assets/navold2.jpg"); */
  background-size: cover;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: var(--nav-width);
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 102;
  font-size: 3rem;
  /* border: 10px solid transparent;
    padding: 15px;
    border-image: url(./assets/appstore.svg) 30 round; */
}

@media (width< 850px) {
  .nav-top-bar {
    height: 20%;
  }
  .nav-btm-br {
    height: 80%;
  }
}
@media (width< 1350px) {
  .nav-top-bar {
    font: 1em sans-serif;
  }
}
.ba-close {
  display: none;
  position: absolute;
  right: 10%;
  top: 10%;
  color: transparent;
  font-size: 2rem;
  border-radius: 50%;
  border: 1px solid transparent;
  padding: 7px;
  cursor: pointer;
}

.visible {
  display: flex;
}
.whatsapp {
  position: fixed;
  bottom: 5%;
  right: 2%;
  z-index: 101;
}
.whatsapp i {
  font-size: 4rem;
  color: #25d366;
}
.up {
  width: 4rem;
  height: 4rem;
  font-size: large;
  color: white;
  background-color: var(--primary--color);
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.up:hover {
  color: var(--secondry--color);
}
</style>
